.inputContainer {
	margin-bottom: 17px;
}

.listContainer {
  padding-left: 20px;
}

.listItem-close{
  color:#E00000;
  font-weight:bold;
  background:none;
  border:none;
  font-size: 20px;
}

.hidden{
  display:none !important;
}

.form-control {
  max-width: 370px;
}

.inputLabel {
	margin-bottom: 5px;
}

.normalInput {
	background-color: #F7F7F7;
}

.normalInput-inline {
  background-color: #F7F7F7;
  margin:0px 5px 0px 0px;
}

.largeInput{
  background-color: #F7F7F7;
  width:250px;
  height:60px;
}

.inputTextArea{
  resize: none;
}

.dropdownSelect {
  position: absolute;
  height:60.5px !important;
	background-color: #F7F7F7;
}

.drpodownInput {
  width:200px;
  background-color: #F7F7F7;
}

.dropdownTextArea {
  z-index: 1;
  border-color: transparent;
}

.componentSeparator {
  border-bottom:solid 1px #0A793E;
  margin:0px 50px 10px 50px;
}

.datePicker {
  width:200px;
  height:36px;
  background-color: #F7F7F7;
}

.normalButton {
  color: #000;
  background-color: #C5E4D0;
  border-color: transparent;
  padding: 8px 20px;
  margin-left: 20px;
}

.normalButton_Left {
  padding: 8px 20px;
  margin-right: 20px;
  margin-left: 0px;
}

.normalButton:hover {
  color: #000;
  background-color: #A5E0B0;
}

.primaryButton {
  color: #fff !important;
  background-color: #0A793E;
}

.primaryButton:hover {
  color: #fff;
  background-color: #076030;
}

.normalFont {
  font-family: Microsoft JhengHei,\\5FAE\8EDF\6B63\9ED1\9AD4,Montserrat,Arial,"sans-serif";
  font-size:16px;
  color:BLACK;
}

.boldFont {
  font-family: Microsoft JhengHei,\\5FAE\8EDF\6B63\9ED1\9AD4,Montserrat,Arial,"sans-serif";
  font-size:16px;
  font-weight:bold;
  color:BLACK;
}

.smallFont {
  font-family: Microsoft JhengHei,\\5FAE\8EDF\6B63\9ED1\9AD4,Montserrat,Arial,"sans-serif";
  font-size:13px;
  color:BLACK;
}

.mandatoryInput::after {
  color: #0A793E;
  content: "*";
}

.custom-control-label {
  vertical-align: center;
}

.radioButtonInput {
  margin-top: 10px;
}

.validationFailed {
  color:#9f3a38 !important;
}

.validationFailed .form-control {
    border-color: #9f3a38 !important;
}

.validationPassed {
  color:#3c763d !important;
}

.validationPassed .form-control {
    border-color: #3c763d !important;
}

li {
  font-size: 16px;
  font-weight: bold;
  color:#0A793E;
}

ol {
    counter-reset: list;
}
ol  li {
    list-style: none;
}
ol  li:before {
    content: "("counter(list)")";
    counter-increment: list;
    margin-left: -30px;
    position: absolute;
}