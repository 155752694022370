/* @font-face {
	font-family: 'MHeiHKMedium';
    src: url("/MHeiHK-Medium.otf") format("opentype");
} */

body {
    background-color: #dae9da;
    white-space: pre-line;
}

li {
	margin-bottom:5px;
}

span.pdfHighlightedKeyword {
	/* font-family: 'MHeiHKMedium'; */
	background-color: rgba(0, 131, 255, 0.2); 
}

@media print {
    body { display: none; }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.loading-overlay {
	background-color: rgba(0,0,0,0.5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	z-index: 9999999;
}

.title {
	min-width: 390px;
	font: 1em "Century Gothic", Futura, sans-serif;
	font-weight: bold;
	overflow: visible;
	display:inline-block;
	margin-bottom: 0px;
}

.topBarRow {
	width:100%;
	height:100%;
	margin: 0px 0px 0px 0px;
	align-items: center;
}

.topBarButtonRow {
	font-size: 0.8em;
	align-self: flex-end;
	height:20px;
	margin-top: -10px;
}

.linkToButton{
	z-index:1;
	border:none; 
	background-color:transparent;
	color:green;
	text-decoration: underline;
}

.pageNum {
	margin-left:10px;
	margin-bottom:0px;
	font-size:20px;
	font-weight: bold;
}

.rootContainer{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.districtTab a{
	color:green;
}

.noResultText {
	color: red;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 5px 30px;
}

.showingText {
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 5px 30px;
}

.backToSearchText {
	color:green;
	align-self: center;
	font-size:1.25em;
	margin-left:-20px;
	cursor: pointer;
}

.searchResultText {
	font-size:1.1em;
	font-weight: bold;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 5px 30px;
}

.searchResultList {
	overflow-x:hidden;
	overflow-y:auto;
	padding-top: 10px;
}

.searchResultList li p{
	text-align: left;
	margin-bottom: 5px;
}

.searchResultList ul {
    list-style: none;
    position:relative;
}

.searchResultList ul li:before {
    content: "\2022";
    font-size: 1.1em;
    counter-increment: list;
    margin-left: -16px;
    position: absolute;
}

.searchResultListText {
	font-size:0.8em;
}

.pageIndex {
	border: none;
	background-color: transparent;
	color: green;
	font-size: 15px;
}

.selectedPageIndex {
	font-weight: bold;
}

.checkbox{
	padding:0px 0px 0px 0px;
	align-items: center;
}

.readonlyCheckBoxRow {
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 5px 30px;
}

.readonlyCheckBox {
	padding:0px 0px 0px 0px;
	margin-bottom: 0px;
	align-items: center;
}

.readonlyCheckBox label {
	font-weight: normal;
	font-size: 1em;
	padding-left: 5px;
}

.showMore {
	font-size: 1.1em;
	font-weight: bold;
	cursor: pointer;
}

.criteria-label {
	padding-left: 5px;
}

.pdfZoomDiv {
	position:absolute;
	right:0;
	padding-right: 10px;
}

.pdfZoomButton {
	flex:1;
	color:green;
	font-size:0.9em;
	border:none;
	background-color:transparent;
}

.searchKeyInput {
	padding-left:5px;
}

.searchCountInput {
	padding: 0px 8px;
	min-width:90px;
	max-width:90px;
}

.searchCountInput input {
	padding: .375rem .35rem;
}

mark{
	background-color: #fcf873;
}

.pdfViewer{
	max-width:100%;
	max-height:95%;
}

.pdfViewerPage, .previousPageBtn, .nextPageBtn{
	display: none;
}

.pdfCanvasDiv {
	max-height:90vh;
	flex:1;
	overflow-y: auto;
	overflow-x: auto;
	background: darkgray;
}

.pdfCanvasDiv .container {
	margin: 0px 0px;
	max-width:none;
	padding: 0px 0px;
}

.pdfViewerCanvas {
	overflow: auto;
	position:relative;
}

.textLayer div {
	position:absolute;
	font-family: MHeiHK, sans-serif !important;
}

.textLayer {
	top:5px;
	color:transparent;
	opacity:1;
}

.watermark {
	position:absolute;
	color: red;
	width:100%;
	max-width:100%;
	bottom:1.5%;
	left:50%;
	transform: translateX(-50%);
	text-align: center;
}

.react-pdf__Page__textContent {
	transform: none !important;
	top:0px !important;
	left:0px !important;
}