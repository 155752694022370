html, body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin:0px;
  height:100%;
}

.rootContainer{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: none;
  padding:0px;
  margin:0px;
}

.verticalCenterChild{
  display:flex;
  align-items: center;
}

.headerBackground {
  display:flex;
  align-items: center;
  justify-content: center;
  flex:1;
  background-color:#FFFFFF;
  width:100%;
  min-height:100px;
  padding: 10px 0 13px;
}

.topBar {
  min-height:100px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.4em;
}

.langButton {
  background: transparent;
  border: 0px;
  color: #0A793E;
  padding-bottom: 10px
}

.bodyBackground {
  display:flex;
  justify-content: center;
  background-color:#E9E9E9;
  flex:1;
}

.footerBackground {
  display:flex;
  justify-content: center;
  background-color:#FFFFFF;
  height:150px;
  min-height:150px;
  padding: 60px 0 60px 0;
}

.lrLogoLeftCol {
  max-width:360px;
  height:100%;
  padding-left: 0px;
  margin-left: 0px;
}

.lrLogoRightCol {
  max-width:180px;
  height:100%;
  padding-left: 0px;
  margin-left: 0px;
}


.lrLogo {
  height:95px;
}

.body {
  width:1200px;
  max-width:1200px;
  height:100%;
}

.formTitle {
  font-weight: 400;
  font-size: 2.5em;
  padding:27px 0px 27px;
}

.contentPane {
    flex:3;
    margin-bottom: 17px;
}

.inputForm {
    flex:1;
    background-color:#FFFFFF;
    border-bottom:3px solid #0A793E;
    padding: 20px 40px 20px 20px;
    margin-bottom: 17px;
}

.inputFormValFailed {
    border-bottom-color: #9f3a38 !important;
    background-color: #fff6f6 !important;
}

.alignRight {
  text-align:right;
}

.sectionTitle {
  padding-bottom: 15px;
  margin:0px;
  font-size: 1.3em;
  font-weight: 700;
  color: #0A793E;
}